<template>
  <!-- <div class="address-filter-container" v-if="googleEnabled"> -->
  <div class="address-filter-container">
    <div class="d-flex w-100 google-autocomplete-input-container rounded-pill">
      <vue-google-autocomplete
        id="pac-input"
        classname="google-autocomplete-input address-to-search"
        ref="addresstosearchref"
        :placeholder="
          $t('navbar.service.' + selectedDeliveryServiceId + '.searchLabel')
        "
        :types="'geocode'"
        v-on:placechanged="addressToSearchChanged"
        :enable-geolocation="true"
        country="it"
        @keyup.enter.native="searchFirst"
      >
      </vue-google-autocomplete>

      <v-btn
        class="geolocate-btn"
        color="primary"
        icon
        @click="resetNearWarehouse()"
        v-if="showNearWarehouse"
      >
        <v-icon>$close</v-icon>
      </v-btn>
      <v-btn
        class="geolocate-btn"
        color="primary"
        icon
        @click="geolocate()"
        v-else
      >
        <v-icon>$myLocation</v-icon>
      </v-btn>
    </div>
    <!-- <div
      class="d-flex align-start align-sm-center flex-column flex-sm-row mt-4 mt-sm-0 filter-text"
    >
      Raggio di ricerca:
      <v-radio-group
        class="ml-1"
        dense
        v-model="distanceRange"
        row
        @change="distanceRangeChanged()"
      >
        <v-radio color="primary" :value="10000">
          <template v-slot:label>
            <span class="distance-label distance-radio-label">
              10km
            </span>
          </template>
        </v-radio>
        <v-radio color="primary" :value="30000">
          <template v-slot:label>
            <span class="distance-label distance-radio-label">
              30km
            </span>
          </template>
        </v-radio>
        <v-radio color="primary" :value="60000">
          <template v-slot:label>
            <span class="distance-label distance-radio-label">
              60km
            </span>
          </template>
        </v-radio>
      </v-radio-group>
    </div> -->
  </div>
</template>
<style lang="scss">
.address-filter-container {
  .google-autocomplete-input-container {
    display: flex;

    border: 1px solid var(--v-grey-base);
    padding: 3px 6px 3px 15px;
    background: #ffffff;
    font-size: 14px;
    .google-autocomplete-input {
      width: inherit;
      &:focus {
        outline: none;
      }
    }
  }
}
</style>
<script>
import VueGoogleAutocomplete from "vue-google-autocomplete";

export default {
  name: "AddressFilter",
  components: { VueGoogleAutocomplete },
  props: {
    google: { type: Object, required: true },
    selectedDeliveryServiceId: { type: Number, default: 1 }
  },
  data() {
    return {
      distanceRange: 10000,
      addressToSearch: null,
      showNearWarehouse: false
    };
  },
  computed: {},
  methods: {
    resetNearWarehouse() {
      this.showNearWarehouse = false;
      this.addressToSearch = null;
      document.getElementById("pac-input").value = "";
      this.$emit("resetNearWarehouse");
    },
    emitSearchAddress() {
      this.showNearWarehouse = true;
      this.$emit("addressToSearchChanged", this.addressToSearch);
    },
    distanceRangeChanged() {
      if (this.addressToSearch) {
        this.addressToSearch.distance = this.distanceRange;
        this.emitSearchAddress();
      }
    },
    addressToSearchChanged(address) {
      this.addressToSearch = {
        latitude: address.latitude,
        longitude: address.longitude,
        distance: this.distanceRange
      };
      this.emitSearchAddress();
    },
    geolocate() {
      let vm = this;
      if (navigator.geolocation) {
        document.getElementById("pac-input").value = "";
        document.getElementById("pac-input").placeholder =
          "Localizzazione in corso...";
        navigator.geolocation.getCurrentPosition(
          function(position) {
            var latlng = new vm.google.maps.LatLng(
              position.coords.latitude,
              position.coords.longitude
            );
            let addressData = null;
            var geocoder = new vm.google.maps.Geocoder();
            geocoder.geocode({ location: latlng }, function(results, status) {
              if (status == vm.google.maps.GeocoderStatus.OK) {
                addressData = results[0];
                vm.addressToSearch = {
                  latitude: addressData.geometry.location.lat(),
                  longitude: addressData.geometry.location.lng(),
                  distance: vm.distanceRange
                };
                document.getElementById("pac-input").placeholder = vm.$t(
                  "navbar.service." +
                    vm.selectedDeliveryServiceId +
                    ".searchLabel"
                );
                document.getElementById("pac-input").value =
                  addressData.formatted_address;
                vm.emitSearchAddress();
              } else {
                console.log(
                  "Geocode was not successful for the following reason: "
                );
              }
            });
          },
          function(err) {
            console.log(err);
            alert(
              "è necessario abilitare la localizzazione per utilizzare questa funzione"
            );
          },
          { enableHighAccuracy: false, maximumAge: Infinity }
        );
      }
    }
  }
};
</script>
