var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{staticClass:"warehouse-list"},[_c('h1',[_vm._v(_vm._s(_vm.$t("warehouse.title")))]),(_vm.warehouseList && _vm.warehouseList.length > 0)?_c('v-row',{attrs:{"no-gutters":""}},[(_vm.showListParsed)?_c('v-col',{staticClass:"py-0 py-md-3",attrs:{"order":"2","order-sm":"1","cols":"12","sm":_vm.showListParsed ? '5' : '0',"xl":_vm.showListParsed ? '4' : '0'}},[_c('v-list',{staticClass:"warehouse-list-list",attrs:{"height":_vm.mapHeight},on:{"click":_vm.clicked}},[(_vm.google)?_c('AddressFilter',{staticClass:"px-4 pb-4",attrs:{"google":_vm.google},on:{"addressToSearchChanged":_vm.addressToSearchChanged,"resetNearWarehouse":_vm.resetNearWarehouse}}):_vm._e(),_vm._l((_vm.warehouseList),function(warehouse,index){return [_c('v-list-item',{key:warehouse.warehouseId,staticClass:"d-block pt-1",attrs:{"two-line":"","dense":""},on:{"click":function($event){return _vm.toggleInfoWindow(warehouse)}}},[_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"text-left font-weight-bold mb-2"},[_vm._v(" "+_vm._s(warehouse.name || warehouse.address.addressName)+" ")]),_c('v-list-item-subtitle',{staticClass:"warehouse-address"},[_vm._v(" "+_vm._s(warehouse.address.address1)+","+_vm._s(warehouse.address.addressNumber)+" "+_vm._s(warehouse.address.city)+" ("+_vm._s(warehouse.address.province)+") "),(
                    warehouse.metaData.warehouse_locator &&
                      warehouse.metaData.warehouse_locator.PHONE
                  )?_c('div',{staticClass:"py-1"},[_vm._v(" Telefono: "+_vm._s(warehouse.metaData.warehouse_locator.PHONE)+" ")]):_vm._e(),_c('div',{staticClass:"d-flex justify-end pt-4"},[_c('a',{staticClass:"v-btn primary v-size--small white--text",attrs:{"href":'/punti-vendita/' + warehouse.slug},on:{"click":_vm.clicked}},[_vm._v(" VAI ALLA SCHEDA ")])])])],1)],1),(index !== _vm.warehouseList.length - 1)?_c('v-divider',{key:warehouse.warehouseId}):_vm._e()]})],2)],1):_vm._e(),_c('v-col',{staticClass:"py-0 py-md-3",attrs:{"order":"1","order-sm":"2","cols":"12","sm":_vm.showListParsed ? '7' : '0',"xl":_vm.showListParsed ? '8' : '0'}},[_c('GmapMap',{ref:"mapRef",style:({ width: _vm.mapWidth, height: _vm.mapHeight }),attrs:{"center":{
          lat: _vm.addressToSearch ? _vm.addressToSearch.latitude : 42.52832317314753,
          lng: _vm.addressToSearch ? _vm.addressToSearch.longitude : 9.025368227739763
        },"zoom":_vm.zoomLevel,"options":{
          zoomControl: true,
          mapTypeControl: false,
          scaleControl: false,
          streetViewControl: false,
          rotateControl: false,
          fullscreenControl: false,
          disableDefaultUI: false
        }}},[_vm._l((_vm.warehouseList),function(warehouse,i){return _c('GmapMarker',{key:warehouse.warehouseId,ref:'warehousMarker_' + warehouse.warehouseId,refInFor:true,attrs:{"position":_vm.google &&
              new _vm.google.maps.LatLng(
                warehouse.address.latitude,
                warehouse.address.longitude
              ),"clickable":true,"icon":"/img_layout/map_pointer.png"},on:{"click":function($event){return _vm.toggleInfoWindow(warehouse, i)}}})}),_c('gmap-info-window',{attrs:{"options":_vm.infoOptions,"position":_vm.infoWindowPos,"opened":_vm.infoWinOpen},on:{"closeclick":function($event){$event.stopPropagation();_vm.infoWinOpen = false}}},[_vm._v(" "+_vm._s(_vm.selectedWarehouse)+" Contenuto ")]),(_vm.addressToSearch)?_c('gmap-circle',{attrs:{"center":{
            lat: _vm.addressToSearch.latitude,
            lng: _vm.addressToSearch.longitude
          },"radius":_vm.addressToSearch.distance,"visible":true,"options":{ fillOpacity: 0.3, strokeWeight: 0.3 }}}):_vm._e()],2)],1)],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }